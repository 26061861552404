import React from "react";
import Header from "./components/Header.tsx";
import Images from "./components/Images.tsx";
import Footer from "./components/Footer.tsx";
import Video from "./components/Video.tsx";
import FormsCause from "./components/FormsCause.tsx";
import Depoiments from "./components/Depoiments.tsx";
import Background from "./components/Background.tsx";
import "./App.css";
import PrimaryText from "./components/PrimaryText.tsx";

function App() {
  return (
    <div>
      <Header />
      <PrimaryText />
      <Video />
      <Background />
      <Images />
      <FormsCause />
      <Depoiments />
      <Footer />
    </div>
  );
}

export default App;
