import React from "react";
import "./Background.css";
import background from "../assets/background.png";

function Background() {
  return (
    <div>
      <img
        className="custom-img-back"
        src={background}
        alt="background"
        style={{
          zIndex: "-1",
          position: "absolute",
          top: "90vh",
          opacity: 0.2,
          maskImage:
            "linear-gradient(to bottom, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 100%)",
          WebkitMaskImage:
            "linear-gradient(to bottom, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 100%)",
        }}
      />
    </div>
  );
}

export default Background;
