import React from "react";
import "./Images.css";
import plataforma from "../assets/plataforma.png";
import logoBranco from "../assets/logo branco.png";

function Images() {
  return (
    <div className="layout-container">
      {/* Primeira Parte */}
      <div className="section section-top">
        <div className="text-container">
          <h2>Descreva o que aconteceu...</h2>
          <p>
            E nossa IA irá lhe fornecer não só a probabilidade de sua causa ser
            ganha, como também sugerirá advogados para você vincular à sua
            causa!
          </p>
          <hr className="my-4" />

          <p>Principais funcionalidades:</p>
          <ul className="small  text-muted">
            <li className="d-flex align-items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#0d9276"
                className="bi bi-check-circle-fill me-2"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              Gerencie suas causas
            </li>
            <li className="d-flex align-items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#0d9276"
                className="bi bi-check-circle-fill me-2"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              Conecte-se com advogados
            </li>
            <li className="d-flex align-items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#0d9276"
                className="bi bi-check-circle-fill me-2"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              Avaliação de causa feita por inteligência artificial
            </li>
          </ul>
          <button>
            {" "}
            <a
              className="text-reset text-decoration-none"
              href="https://www.causaganha.app/auth"
            >
              {" "}
              Começar
            </a>
          </button>
        </div>
        <div className="image-container">
          <img src={plataforma} alt="Planejamento do projeto" />
        </div>
      </div>

      {/* Segunda Parte */}
      <div className="section section-bottom">
        <div className="card">
          <div className="d-flex align-items-center gap-2 justify-content-between">
            <h3 className="fw-bold">É usuário?</h3>
            <img src={logoBranco} alt="logo branco" />
          </div>
          <p>A Causa Ganha pode te ajudar em:</p>
          <ul>
            <li className="d-flex align-items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#ffffff"
                className="bi bi-check-circle-fill me-2"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              Seja auxiliado por nossa inteligência artificial
            </li>
            <li className="d-flex align-items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#ffffff"
                className="bi bi-check-circle-fill me-2"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              Entenda melhor a probabilidade da sua causa
            </li>
            <li className="d-flex align-items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#ffffff"
                className="bi bi-check-circle-fill me-2"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              Conecte-se com nossos advogados sem burocrácia
            </li>
          </ul>
          <div className="small me-3 btn btn-outline-light">
            <a
              className="text-reset text-decoration-none"
              href="https://www.causaganha.app/auth/registration"
            >
              Cadastra-se como usuário
            </a>
          </div>
        </div>
        <div className="card">
          <div className="d-flex align-items-center gap-2 justify-content-between">
            <h3 className="fw-bold">É advogado?</h3>
            <img src={logoBranco} alt="logo branco" />
          </div>
          <p>A Causa Ganha pode te ajudar em:</p>
          <ul>
            <li className="d-flex align-items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#ffffff"
                className="bi bi-check-circle-fill me-2"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              Gestão das suas causas.
            </li>
            <li className="d-flex align-items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#ffffff"
                className="bi bi-check-circle-fill me-2"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              Mais clientes conectados a você.
            </li>
            <li className="d-flex align-items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#ffffff"
                className="bi bi-check-circle-fill me-2"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              Melhor respaudo de leis e jurisdição.
            </li>
          </ul>
          <div className="small me-3 btn btn-outline-light">
            <a
              className="text-reset text-decoration-none"
              href="https://www.causaganha.app/auth/login-lawyer"
            >
              Cadastra-se como advogado
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Images;
