import React, { useState } from "react";
import "./FormsCause.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function FormsCause() {
  const [description, setDescription] = useState("");

  const handleSaveDescriptionAndRedirect = () => {
    if (description.trim()) {
      try {
        localStorage.setItem("causeDescription", description);
        const encodedDescription = encodeURIComponent(description);

        window.location.href = `https://www.causaganha.app/auth?causeDescription=${encodedDescription}`;
      } catch (error) {
        toast.error("Ocorreu um erro ao salvar a descrição. Tente novamente.");
      }
    } else {
      toast.error("Por favor, preencha a descrição da causa.");
    }
  };

  return (
    <div className="container text-center py-5">
      <ToastContainer
        style={{ display: "flex", textAlign: "start", fontSize: "0.8rem" }}
      />
      <div className="col d-flex row justify-content-center">
        <h2
          style={{
            color: "#585A63",
            fontWeight: "900",
            fontFamily: "'Montserrat', sans-serif",
          }}
        >
          Crie até 02 causas <br />
          totalmente de graça!
        </h2>
        <span className="text-muted small">Crie sua primeira agora mesmo!</span>
        <div className="row mt-5" style={{ maxWidth: "70vh" }}>
          <span className="row fw-bold h5 mb-0" style={{ color: "#585A63" }}>
            Descreva sua causa
          </span>
          <span
            className="row text-muted mb-4 small"
            style={{ color: "#585A63" }}
          >
            Seja o mais detalhista possível*
          </span>
          <textarea
            className="form-control custom-placeholder"
            placeholder="Ex: Sou de São Paulo e sou gestante, estou afastada por conta disso. Meu chefe sabendo mesmo assim me demitiu sem ao menos ter uma conversa e saber o motivo, gostaria de saber se possuo algum direito que me resguarda."
            style={{ minHeight: "200px", color: "#585A63", fontSize: "0.8em" }}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
          <button
            type="button"
            className="btn text-white mt-4"
            style={{ backgroundColor: "#0d9276", borderRadius: "20px" }}
            onClick={handleSaveDescriptionAndRedirect}
          >
            Saiba o resultado
          </button>
        </div>
      </div>
    </div>
  );
}

export default FormsCause;
