import React from "react";
import "./Footer.css";
import Logo from "../assets/apple.png";
import LogoGoogle from "../assets/google-play.png";

import facebook from "../assets/facebook.png";
import instagram from "../assets/instagram.png";
import linkedin from "../assets/linkedin.png";
import tiktok from "../assets/tiktok.png";
import twitter from "../assets/twitter.png";
import youtube from "../assets/youtube.png";

function Footer() {
  return (
    <>
      <div className="text-center bg-dark text-white py-5">
        <div className="container">
          <div className="mb-4">
            <h2 className="fw-bold">Consulte suas causas agora mesmo</h2>
            <p>
              Teste grátis até duas causas | Não é necessário cartão de crédito
            </p>
            <button className="btn btn-primary">
              <a
                className="text-reset text-decoration-none"
                href="https://www.causaganha.app/auth"
              >
                Começar
              </a>
            </button>
          </div>
        </div>
      </div>
      <div className=" text-center  mt-5 px-5">
        <hr className="my-4" />
        <div className="row row-cols- row-cols-md-8 py-3 mx-2  align-items-center app">
          <div
            className="d-flex col bg-dark ms-1 rounded py-2 justify-content-center align-items-center app"
            style={{ minWidth: "140px", maxWidth: "140px" }}
          >
            <img
              src={Logo}
              alt="Apple Logo"
              className=" img-fluid me-2 "
              style={{ maxWidth: "30px", maxHeight: "30px" }}
            />
            <div className="row d-flex text-start">
              <span className="text-white" style={{ fontSize: "8px" }}>
                Download em
              </span>
              <span className="text-white fw-bold small">App Store</span>
            </div>
          </div>
          <div
            className="d-flex col bg-dark ms-1 rounded py-2 justify-content-center align-items-center app"
            style={{ minWidth: "160px", maxWidth: "160px" }}
          >
            <img
              src={LogoGoogle}
              alt="Apple Logo"
              className=" img-fluid me-2 "
              style={{ maxWidth: "30px", maxHeight: "30px" }}
            />
            <div className="row d-flex text-start">
              <span className="text-white" style={{ fontSize: "8px" }}>
                Download em
              </span>
              <span className="text-white fw-bold small">Google Store</span>
            </div>
          </div>
          <div className="col d-flex flex-column align-items-center align-items-md-end small">
            <div className="col-md-6 d-flex justify-content-md-end justify-content-center w-100">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <a href="#" className="btn btn-outline-social">
                    <img
                      src={linkedin}
                      alt="Linkedin"
                      style={{ width: "30px" }}
                    />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#" className="btn btn-outline-social">
                    <img
                      src={facebook}
                      alt="facebook"
                      style={{ width: "30px" }}
                    />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#" className="btn btn-outline-social">
                    <img
                      src={twitter}
                      alt="twitter"
                      style={{ width: "30px" }}
                    />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#" className="btn btn-outline-social">
                    <img
                      src={youtube}
                      alt="youtube"
                      style={{ width: "30px" }}
                    />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#" className="btn btn-outline-social">
                    <img
                      src={instagram}
                      alt="instagram"
                      style={{ width: "30px" }}
                    />
                  </a>
                </li>

                <li className="list-inline-item">
                  <a href="#" className="btn btn-outline-social">
                    <img src={tiktok} alt="tiktok" style={{ width: "30px" }} />
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-md-6 d-flex justify-content-md-end justify-content-cente w-100">
              <ul className="list-inline separated mb-0">
                <li className="list-inline-item">
                  <a
                    href="https://www.causaganha.app/auth/terms-conditions"
                    className="text-dark"
                  >
                    Termos e condições
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.causaganha.app/auth/policy-privacy"
                    className="text-dark"
                  >
                    Nossa política de privacidade
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.causaganha.app/auth/policy-privacy"
                    className="text-dark"
                  >
                    Cookies
                  </a>
                </li>
              </ul>
            </div>
            <div className="mt-4 text-center text-md-end">
              <p>
                &copy; 2024 Causa Ganha | Todos os direitos reservados ©
                causaganha.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
