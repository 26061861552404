import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Depoiments.css";
import mulherCacheada from "../assets/mulher-cacheada.jpg";
import homem from "../assets/homem.jpg";
import mulherSorrindo from "../assets/mulher-sorrindo.jpg";

function Depoiments() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonials = [
    {
      text: "O Work OS da Causa Ganha economiza cerca de 1.850 horas da equipe e algo na faixa de US$ 50.000 por mês.",
      author: "Stefana Muller",
      position: "Diretor Sênior",
      company: "Oscar",
      image: mulherCacheada,
    },
    {
      text: "A plataforma Causa Ganha nos permitiu organizar melhor nossas tarefas e aumentar a produtividade.",
      author: "Carlos Silva",
      position: "Gerente de Projetos",
      company: "Tech Solutions",
      image: homem,
    },
    {
      text: "Usar o Causa Ganha foi um divisor de águas para nossa equipe. Conseguimos reduzir o tempo de planejamento pela metade.",
      author: "Mariana Costa",
      position: "Coordenadora de Operações",
      company: "Creative Agency",
      image: mulherSorrindo,
    },
  ];

  return (
    <div className="layout-depoiments-container">
      <div className="section-depoiments section-depoiments-top">
        <div className="depoiments-container">
          <h2>Veja como nossos clientes geram impacto</h2>
          <a href="https://www.causaganha.app/auth" className="btn btn-primary">
            Leia mais histórias de sucesso &rarr;
          </a>
        </div>

        <div className="text-depoiments-container">
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <div key={index} className="testimonial-slide">
                <div className="testimonial-content">
                  <div className="testimonial-image">
                    <img src={testimonial.image} alt={testimonial.author} />
                  </div>
                  <div className="testimonial-text">
                    <h4 className="fw-bold">{testimonial.author}</h4>
                    <p>{testimonial.position}</p>
                    <p>{testimonial.company}</p>
                    <p className="mt-4">{`"${testimonial.text}"`}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Depoiments;
