import React from "react";
import "./Video.css";

function Video() {
  return (
    <div className="layout-video-container">
      <div className="section section-video-top ">
        <div className="video-container">
          <iframe
            className=""
            src="https://www.youtube.com/embed/k65i2E_3n94"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="text-video-container">
          <h2>Veja se você tem chance de ganhar o seu processo</h2>
          <div className="text-p-button">
            <p>
              Utilize nossa Inteligência Artificial para consultar as chances de
              ganho de suas causas!
            </p>

            <button>
              <a
                className="text-reset text-decoration-none"
                href="https://www.causaganha.app/auth"
              >
                Testar grátis
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Video;
