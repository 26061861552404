import React from "react";

function PrimaryText() {
  return (
    <div className="pt-5">
      {" "}
      <div
        className="text-center d-flex  justify-content-center"
        style={{
          maxWidth: "100%",
          background: "linear-gradient(10deg, #b4e0b9 -90%, #0d9276 100%)",
        }}
      >
        <h3
          className="p-5"
          style={{
            color: "#ffffff",
            fontSize: "34px",
            fontWeight: "900",
            fontFamily: "'Montserrat', sans-serif",
            width: "80%",
          }}
        >
          Plataforma integrada com inteligência artificial para consultar causas
          e conectar você a advogados capacitados
        </h3>
      </div>
    </div>
  );
}

export default PrimaryText;
