import React from "react";
import CausaGanha from "../assets/drawIconText.png";
import "./Header.css"; // Import the CSS file

function Header() {
  return (
    <div className="header-container text-center  header">
      <div className="header-content d-flex justify-content-between align-items-center">
        <div className="logo-container d-flex justify-content-start">
          <img src={CausaGanha} alt="Logo Causa Ganha" className="logo" />
        </div>
        <div className="buttons-container d-flex justify-content-end align-items-center">
          <div className="small btn btn-outline-primary lawyer-button">
            <a
              className="text-reset text-decoration-none"
              href="https://www.causaganha.app/auth/login-lawyer"
            >
              É advogado? Cadastre-se aqui
            </a>
          </div>
          <div className="small btn btn-outline-primary user-button">
            <a
              className="text-reset text-decoration-none"
              href="https://www.causaganha.app/auth/registration"
            >
              É usuário? Cadastre-se aqui
            </a>
          </div>
          <div className="small btn btn-primary login-button">
            <a
              className="text-reset text-decoration-none"
              href="https://www.causaganha.app/auth"
            >
              Login
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
